import request from '@/utils/request';

const PATH = '/api/group';

// 그룹 추가
export const addGroup = (data: any) => request({
  url: `${PATH}/addGroup`,
  method: 'POST',
  data,
});

// 그룹 수정
export const updateGroup = (data: any) => request({
  url: `${PATH}/updateGroup`,
  method: 'POST',
  data,
});

// 그룹 삭제
export const deleteGroup = (data: any) => request({
  url: `${PATH}/deleteGroup`,
  method: 'POST',
  data,
})

// 그룹 매니저를 추가하기 위한 사용자 조회
export const findUser = (data: any) => request({
  url: `${PATH}/findUser`,
  method: 'POST',
  data,
});

// 그룹 정보 상세 조회
export const getGroupInformation = (roomId: string) => request({
  url: `${PATH}/information`,
  method: 'GET',
  params: {
    room_no: roomId,
  },
})

// 그룹 정보 상세 조회
export const getGroupInformation2= (roomId: string) => request({
  url: `${PATH}/information2`,
  method: 'GET',
  params: {
    room_no: roomId,
  },
})

// 그룹 정보 조회
export const getGroupData = (roomId: string) => request({
  url: `${PATH}/groupData`,
  method: 'GET',
  params: {
    group_no: roomId,
    data_type: 'update',
  },
})

// 그룹 활동 추가
export const addActivity = (data: any) => request({
  url: `${PATH}/addActivity`,
  method: 'POST',
  data,
})

// 게시글 정보 조회
export const getWriteInfo = (params: any) => request({
  url: `${PATH}/writeInfo`,
  method: 'GET',
  params,
});

// 게시글 삭제
export const deleteSubjectPost = (data: any) => request({
  url: `${PATH}/deletePost`,
  method: 'POST',
  data,
})

// 참여자 관리
export const getMemberManagement = (roomId: any) => request({
  url: `${PATH}/usersManagement`,
  method: 'GET',
  params: {
    room_no: roomId,
  },
})

// 참여자 패스워드 초기화
export const resetMemberPassword = (roomId: any, userId: any) => request({
  url: `${PATH}/resetMemberPassword`,
  method: 'POST',
  data: {
    roomNo: roomId,
    userId,
  },
})

// 참여자 그룹 탈퇴 처리
export const deleteMember = (roomId: any, userId: any) => request({
  url: `${PATH}/deleteMember`,
  method: 'POST',
  data: {
    roomNo: roomId,
    userId,
  },
})

// 그룹 활동 목록
export const getGroupAcitivityList = (group_no: any) => request({
  url: `${PATH}/activityList`,
  method: 'GET',
  params: {
    group_no,
  },
})


// 그룹 활동 수정
export const updateActivity = (data: any) => request({
  url: `${PATH}/updateActivity`,
  method: 'POST',
  data,
})

// 그룹 활동 삭제
export const deleteActivity = (roomId: any, activityId: any) => request({
  url: `${PATH}/deleteActivity`,
  method: 'POST',
  data: {
    room_no: roomId,
    array_activity_no: [
      activityId,
    ],
  },
})

// 그룹 활동 순서 변경
export const editActivities = (data: any) => request({
  url: `${PATH}/editActivities`,
  method: 'POST',
  data,
});
